.TopBar {
  position: fixed;
  width: 100%;
  height: 40px;
  border-bottom: solid 1px rgba(138, 138, 138, 0.15);
  background: rgba(255, 255, 255, 0.9);
  z-index: 1001;
}
.TopBar .content {
  padding-top: 0.5em;
}

.TopBar .Logo {
  display: inline;
  font-size: 18px;
  font-weight: bold;
  color: #2d353c;
  margin-left: 2em;
  -webkit-transform: translateY(10%);
  -ms-transform: translateY(10%);
  transform: translateY(10%);
}

.ui.dropdown .menu > .item {
  width: 100%;
}
.TopBar ul {
  display: inline;
  list-style: none;
  bottom: 0.5em;
}
.TopBar ul li {
  display: inline;
  margin-right: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
.TopBar ul li a {
  color: #2d353c;
  text-decoration: none;
}
.TopBar ul li.active,
.TopBar ul li.active a {
  position: relative;
  font-weight: bold;
}
/*.TopBar ul li.active::before {
	content: " ";
    background: #253238;
    height: 40px;
    position: absolute;
    width: 100%;
    top: -0.8em;
    left: 0;
}*/
.TopBar ul li.active::after {
  position: absolute;
  content: "";
  top: 26px;
  left: 0;
  background: 0 0;
  width: 100%;
  height: 3px;
  border: none;
  background: #21ba45;
  z-index: 2;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}
