body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  /*font-weight: lighter;*/
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #707478;
}

.imagy {
  background: transparent;
  background-image: url("../img/background.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.app_content {
  padding-top: 40px;
  background-color: rgb(238, 238, 238);
}

.statsChart {
  opacity: 0.6;
}
.statsChart svg {
  position: absolute;
  bottom: 2em;
}
.statsChart svg .mg-x-axis {
  opacity: 0;
}
.building.card:hover svg .mg-x-axis {
  opacity: 1;
}
.statsChart .mg-line1-color:hover {
  opacity: 0.8;
  stroke: red;
}
.statsChart path.mg-main-area {
  opacity: 0.15;
}

._page.segment .top.attached.menu .ui.small.input {
  box-shadow: 0 0 3em rgba(0, 176, 255, 0.33);
}

.ui.fluid.workspace.card .image {
  background: #253238;
  color: white;
}
.workspace.card text {
  fill: white;
}
.workspace.card .content .legends {
  display: none;
  position: absolute;
  z-index: 10001;
  background: #253238;
  margin: -1em;
  border-radius: 5px;
  border: solid 1px #888;
  right: 13px;
  top: calc(100% - 3.5em);
  font-size: 0.9em;
}
.workspace.card .content {
  background: #2f3f46;
}
.workspace.card .content .description {
  color: wheat;
  border: none;
}
.workspace.card .content .legends span {
  display: block;
}
.workspace.card:hover .content .legends {
  display: none;
}
.workspace.card svg .mg-active-datapoint tspan:first-child {
  font-family: Roboto;
  font-size: 11px;
  fill: wheat;
}
.workspace.modal .ui.list .item:hover {
  background-color: rgba(136, 136, 136, 0.1);
}

@font-face {
  font-family: LCD;
  src: url("lcd.woff2") format("woff2");
}

#historyChart .mg-y-rollover-text {
  stroke: black;
}

.groupStatus .flags .flag {
  font-family: Roboto;
  border: solid 1px #9dc4d2;
  border-radius: 2px;
  padding: 0 2px;
  margin: 2px;
}

.alertRule .ui.button {
  width: 36px;
  height: 32px;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
}

.crontimer {
  position: relative;
  height: 300px;
  width: 300px;
  margin: 2.5em auto;
  background: linear-gradient(to bottom, #fafafa 0, #ced2d5 100%);
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #fff, 0 0 2px 0 rgba(0, 0, 0, 0.1),
    0 5px 10px 0 rgba(0, 0, 0, 0.1), 0 0 0 2px #ececec, 0 0 0 3px #fff,
    0 0 0 18px #f4f4f4, 0 0 0 19px #dbdbdb, 0 0 0 20px #fff;
  text-align: center;
}

.crontimer .weekdays {
  position: absolute;
  top: 70px;
  left: 35px;
}

.crontimer .clockpiece {
  position: absolute;
  left: 5em;
  top: 40%;
}

.crontimer .clock {
  display: inline-block;
}

.crontimer .clock input {
  font-size: 2em;
  font-weight: 400;
  border: 1px solid #ccd0d4;
  box-shadow: none;
  width: 2.5em;
  padding: 0.3em !important;
  text-align: center !important;
}
.crontimer .cronDesciption {
  text-shadow: 1px 1px white;
  margin-top: 35%;
}

.crontimer .clock.hour {
  margin-right: 1.5em;
  position: relative;
}
.crontimer .clock.hour:before {
  content: "hour";
  position: absolute;
  top: -1.3em;
  left: 36%;
  text-shadow: 1px 1px white;
}
.crontimer .clock.minute:before {
  content: "minute";
  position: absolute;
  top: -1.3em;
  right: 24%;
  text-shadow: 1px 1px white;
}
.crontimer .clock.hour:after {
  content: ":";
  position: absolute;
  right: -0.5em;
  top: 0.2em;
  font-size: 2em;
  font-weight: bold;
  text-shadow: 1px 1px white;
}
.crontimer .crontype {
  position: absolute;
  bottom: 40px;
  right: 31%;
}

.crontimer .sunBefore {
  position: absolute;
  top: -1.7em;
  right: 0.5em;
  border-radius: 8px !important;
  width: 53px;
  padding-top: 5px !important;
}

.crontimer .sunAfter {
  position: absolute;
  bottom: -1.7em;
  right: 0.5em;
  border-radius: 8px !important;
  width: 53px;
  padding-bottom: 5px !important;
}
.crontimer .suntype {
  z-index: 50;
}
.crontimer .hide {
  display: none !important;
}
.crontimer .button.active {
  background: #929ba1 !important;
  color: #fff !important;
}

.ui.button {
  padding: 0.6em !important;
}

.ui.modal {
  position: fixed;
  z-index: 1001;
  left: 25%;
}

.required {
  color: #db2828;
}

.mapControl {
  position: relative;
  width: 100%;
  height: 200px;
}

.map {
  width: 100px;
  height: 100%;
}

.pricing-table {
  list-style-type: none;
  margin: 0 -10px;
  margin-bottom: 60px;
  padding: 0;
  text-align: center;
}
.pricing-table > li {
  float: left;
  flex: 1, 100px;
  padding: 10px;
  min-width: 300px;
}
.pricing-table.col-4 {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.pricing-table .pricing-container {
  overflow: hidden;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 3px #b6c2c9;
}
.pricing-table h3 {
  background: #242a30;
  margin: 0;
  color: #fff;
  font-size: 14px;
  padding: 15px 30px;
}
.pricing-table .features {
  list-style-type: none;
  margin: 0;
  padding: 0 30px;
}
.pricing-table .features > li {
  padding: 10px 0;
}
.pricing-table .features > li + li {
  border-top: 1px solid #e2e7eb;
}
.pricing-table .price {
  width: 100%;
  background: #2d353c;
}
.pricing-table .price .price-figure {
  display: grid;
  text-align: center;
  padding: 1em;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  gap: 1em;
}
.pricing-table .price .price-number {
  font-size: 28px;
  color: #00a3a3;
  display: block;
}
.pricing-table .price .price-tenure {
  font-size: 12px;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  display: block;
  text-align: center;
}
.pricing-table .footer {
  padding: 15px 20px;
}
.pricing-table .highlight {
  padding: 0;
  margin-top: -30px;
}
.pricing-table .highlight .features > li {
  padding: 15px 0;
}
.pricing-table .highlight h3 {
  padding: 20px 30px;
}
.pricing-table .highlight .price .price-figure {
  height: 90px;
}
.pricing-table .highlight .price .price-number {
  color: #fff;
}

.pricing-container button {
  cursor: pointer;
}

._page {
  border: medium none;
  padding: 1em;
  margin: 0px auto;
}

.content-title {
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 15px;
  margin-top: 0;
}

.content-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  background: #242a30;
  height: 2px;
  bottom: 0;
  left: 50%;
  margin-left: -20px;
}

.content-desc {
  text-align: center;
  margin-bottom: 60px;
  color: #242a30;
  font-size: 18px;
  text-shadow: 1px 1px 1px #fff;
}

.pricing-notes {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 2em;
}

.footer {
  padding: 60px 0;
  background: #242a30;
  text-align: center;
  box-shadow: inset 0 100px 80px -80px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: inset 0 100px 80px -80px rgba(0, 0, 0, 0.7);
  margin: 0;
}

.btn.btn-inverse {
  color: #fff;
  background: #2d353c;
  border-color: #2d353c;
}

.btn {
  padding: 0.9em 1.1em;
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: color 100ms ease-in-out;
  -o-transition: color 100ms ease-in-out;
  -ms-transition: color 100ms ease-in-out;
  -moz-transition: color 100ms ease-in-out;
  -webkit-transition: color 100ms ease-in-out;
  transition-property: color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}
.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open .dropdown-toggle.btn-inverse {
  background: #242a30;
  border-color: #242a30;
}

.btn.btn-theme {
  background: #00b0ff;
  border-color: #00b0ff;
  color: #fff;
}

.btn.btn-theme:focus,
.btn.btn-theme:hover {
  background: #008a8a;
  border-color: #008a8a;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.shadow {
  text-shadow: rgb(6, 138, 0) 0px 0px 1px;
}

.rows {
  display: flex;
  flex-direction: column;
}

.multiSelectSelectionTable {
  display: grid;
}

.multiSelectSelectionTable ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.multiSelectSelectionTable {
  background-color: white;
}

.multiSelectSelectionTable ul:nth-child(2n) {
  background-color: #ddebf7;
}

.multiSelectSelectionTable ul:first-child {
  background-color: #009fda;
  color: white;
}

.hidden {
  display: none;
}

.devices-tree-grid {
  grid-area: first;
}

.device-detail-grid {
  grid-area: second;
}

.building-group-pannel {
  grid-area: third;
}

.building-parts-and-devices-grid {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "first first second second second"
    "first first third third third";
}

.ui.top.attached.menu.content {
  height: 48px;
}

.ag-cell .ui.label {
  vertical-align: top;
}

.select.field {
  width: 100%;
  display: grid;
}

.select.field select {
  grid-row: 1/2;
  grid-column: 1/2;
  width: 100%;
}

.select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: 100%;
}

.ui.menu .button.active.item {
  background-color: white;
  font-weight: bold;
}

.ui.menu .button.item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
}

.ui.menu .button.active.item::after {
  position: absolute;
  content: "";
  top: 43px;
  left: 0;
  background: 0 0;
  width: 100%;
  height: 3px;
  border: none;
  background: #21ba45;
  z-index: 2;
  transition: background 0.1s ease;
}

.ui.primary.button.upload-button {
  margin-top: 10px;
}

.ag-row .ag-cell-wrapper.ag-row-group.ag-row-group-indent-1 {
  padding-left: 15px;
}

.ag-row .ag-cell-wrapper.ag-row-group.ag-row-group-indent-2,
.ag-theme-balham .ag-ltr .ag-row-level-2 .ag-row-group-leaf-indent,
.ag-theme-balham .ag-ltr .ag-row-group-indent-2 {
  padding-left: 30px;
}

.ag-row .ag-cell-wrapper.ag-row-group.ag-row-group-indent-3 {
  padding-left: 45px;
}

div .ag-theme-balham .ag-ltr .ag-row-drag,
div .ag-theme-balham .ag-ltr .ag-selection-checkbox,
div .ag-theme-balham .ag-ltr .ag-group-expanded,
div .ag-theme-balham .ag-ltr .ag-group-contracted {
  margin-right: 5px;
}

div .ag-theme-balham {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.ag-cell {
  color: #5e5e5e;
}

.ag-group-expanded .ag-icon.ag-icon-tree-open {
  color: black;
}

.free-trial-list-item {
  padding-bottom: 1em;
  color: rgb(101, 221, 25);
}

.pricing-container .btn.btn-inverse.btn-block:hover {
  color: lightgrey;
}

.subscription-container {
  display: flex;
  justify-content: center; /* Centers the subscription details within content-desc */
}

.subscription-details {
  display: grid;
  grid-template-columns: auto 1fr; /* Creates two columns */
  gap: 10px; /* Adjust the spacing between rows as needed */
  max-width: 700px; 
}

.subscription-row {
  display: contents; /* Ensures rows line up without creating a block effect */
}

.subscription-label {
  text-align: left;
  font-weight: bold; /* Optional for emphasis */
}

.subscription-value {
  text-align: left;
}