.blinkingCaret {
  font-weight: bold;
  color: #00cc00;  /* Adjust the color as needed */
  animation: blink 1s step-start infinite;
}

  
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
  
.terminal {
  background-color: black;
  color: #00cc00;
  font-family: monospace;
  padding: 10px;
  height: 200px;
  overflow-y: scroll;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 1px solid green;
  white-space: pre-wrap; /* Preserve spaces and line breaks */
}

.vpninfo-list {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  gap: 10px; /* Adjusts the space between items */
  padding: 0; /* Optional: remove list padding for better alignment */
  list-style: none; /* Optional: remove default list styling */
}

.vpninfo-item {
  flex: 1 1 45%; /* Ensures each item takes roughly half of the container width */
  display: flex;
  align-items: center;
  gap: 10px;
}


.daemon-list {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap onto the next line */
  gap: 5px; /* Adjusts the space between items */
  padding: 0; /* Optional: remove list padding for better alignment */
  list-style: none; /* Optional: remove default list styling */
}

.daemon-item {
  flex: 1 1 45%; /* Ensures each item takes roughly half of the container width */
  display: flex;
  align-items: center;
  gap: 10px;
}

.city-name {
  margin-left: 10px;
  width: 150px; /* Width for consistent IP address alignment */
}

.ip-address {
  color: black;
}

.vpn-info{
  margin-top: 10px;
  color: black;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9 ;
}

.subscription-name{
  color: white;
  background-color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: large;
  font-size: 25px;
  padding: 20px;
}

.help-button.ui.basic.blue.button {
  box-shadow: none !important; /* Override the Semantic UI shadow */
  border: none !important;
  background-color: transparent !important;
  color: inherit !important; /* Keep text color default or specify a color */
  width: 150px;
}
